@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayBlack.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayBlackItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayBold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayBoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayLight.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayLightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayMedium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayMediumItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayRoman.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayRomanItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayThin.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayThinItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayXThin.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaas';
  src: url('../assets/fonts/NeueHaasDisplayXThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Dalmatins';
  src: url('../assets/fonts/signature/Dalmatins.otf');
  font-weight: normal;
  font-style: normal;
}
