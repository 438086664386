/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  /* max-width: 500px; */
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

/* Buttons */

.slideshowDot {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 5px 0px;
  background-color: #c4c4c4;
}

.active{
  background-color: #0a0a0a;
}

.slideshowDash {
  display: inline-block;
  height: 2px;
  width: 26px;
  border-radius: 3px;

  cursor: pointer;
  margin: 15px 5px 0px;

  background-color: #c4c4c4;
}

.slideshowHollow {
  display: inline-block;
  height: 1.9rem;
  width: 1.9rem;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 5px white;
  border: 1px solid #000;
  cursor: pointer;
  margin: 15px 5px 0px;
}

.make-flex {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
